import { getFirebaseUser, getRememberUser, setFirebaseUser, removeFirebaseUser } from '../helpers/sessionHelper';
import firebaseConfig from '../firebase-config.json'

//Import Env
import { DEBUG } from '../env'

//Import graphql
import graphqlClient from '../graphql/index'
import { GET_USER_BY_AUTH } from "../graphql/userQueries"

export const updateAuth = async function (component, firebase) {

	const user = getFirebaseUser();

	if (user) {
		const userId = user.uid;
		if (userId) {
			component.setState({ isLogin: true });
		}

		var fireAuthLoggedIn = true;
		const rememberUser = getRememberUser();

		if (!rememberUser || (rememberUser && rememberUser.rememberMe === false)) {
			const now = new Date();
			const expired = new Date(user.stsTokenManager.expirationTime);
			fireAuthLoggedIn = now <= expired;
		} else {
			const now = new Date();
			const expired = new Date(user.stsTokenManager.expirationTime);
			if (now >= expired) {
				const successUpdateAccessToken = await updateAccessToken(user, firebase);
		 		fireAuthLoggedIn = successUpdateAccessToken;
			} 
		}

		if (fireAuthLoggedIn === true) {
			component.setState({ isUserEmailVerified: user.emailVerified });
			await updateUserData(component, firebase);
		} else {
			logout(component, firebase);
		}
	}
};

const logout = async function (component, firebase) {
	await firebase.auth().signOut().then(() => {
		if (DEBUG() === true) console.log("Sign-out successful");
	}).catch((error) => {
		// An error happened.
		if (DEBUG() === true) console.log("Sign-out error" + error);
	});

	removeFirebaseUser();
	if (DEBUG() === true) console.log("isLogin false");
	component.setState({ isLogin: false });
}

var updateUserData = async function (component, firebase) {
	if (DEBUG() === true) console.log("updateUserData GET_USER_BY_AUTH");
	await graphqlClient
		.query({
			query: GET_USER_BY_AUTH,
			fetchPolicy: "no-cache"
		})
		.then(result => {
			if (result.errors && result.errors.length > 0) {
				const errMessage = result.errors[0].message;
				if (DEBUG() === true) {
					console.log("err:" + errMessage); 
				} 
				else {
					logout(component, firebase);
				}
			} else {
				component.setState({ isLogin: true });
				component.setState({
					userData: result.data.GetUserByAuth
				});
			}
		})
		.catch(err => {
			if (DEBUG() === true) console.log("graphqlClient err" + err);
		});

}

var updateAccessToken = async function (user, firebase) {
	const stsTokenManager = user.stsTokenManager;
	var success = false;
	const refreshToken = stsTokenManager.refreshToken;
	if (refreshToken) {
		if (DEBUG() === true) console.log("refreshToken:" + refreshToken);
		const apiKey = firebaseConfig.apiKey;

		var headers = new Headers();
		headers.append("Content-Type", "application/x-www-form-urlencoded");

		var urlencoded = new URLSearchParams();
		urlencoded.append("grant_type", "refresh_token");
		urlencoded.append("refresh_token", refreshToken);
		var requestOptions = {
			method: 'POST',
			headers: headers,
			body: urlencoded,
			redirect: 'follow'
		};
		await fetch("https://securetoken.googleapis.com/v1/token?key=" + apiKey, requestOptions)
			.then(response => {
				return response.text();
			})
			.then(result => {
				if (result) {
					const data = JSON.parse(result);
					const access_token = data.access_token;
					if (access_token != null) {
						const uid = data.user_id;
						if (uid === user.uid) {
							if (DEBUG() === true) console.log('success use refreshToken get accessToken');
							user.stsTokenManager.accessToken = access_token;
							setFirebaseUser(user);
							success = true;
						}
					}
				}
			})
			.catch(error => {
				if (DEBUG() === true) console.log('error', error);
			});
	}
	if (!success) {
		if (DEBUG() === true) console.log('error use refreshToken get accessToken');
	}
	return success;
}
