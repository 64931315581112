import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module'
//Import Env
import { DEBUG } from './env'

if (DEBUG() === false) {
    const tagManagerArgs = {
        gtmId: 'GTM-TWJK2VF'
    }

    TagManager.initialize(tagManagerArgs);
}

const app = (
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();