import React from 'react';

//Special
const PageComingSoon = React.lazy(() => import('./main/Maintenance/PageComingSoon'));
const PageError = React.lazy(() => import('./main/Maintenance/PageError'));
const PageMaintenance = React.lazy(() => import('./main/Maintenance/PageMaintenance'));

//Help Center
const HelpCenterOverview = React.lazy(() => import('./main/HelpCenter/HelpCenterOverview'));
const HelpCenterFaqs = React.lazy(() => import('./main/HelpCenter/HelpCenterFaqs'));
const HelpCenterGuides = React.lazy(() => import('./main/HelpCenter/HelpCenterGuides'));
const HelpCenterSupportRequest = React.lazy(() => import('./main/HelpCenter/HelpCenterSupportRequest'));

// Import PinaPromo Pages
const PinaPromoHome = React.lazy(() => import('./main/index'));
const PinaPromoLogin = React.lazy(() => import('./main/Auth/PageLogin'));
const PinaPromoSignup = React.lazy(() => import('./main/Auth/PageSignUp'));
const PersonalDetails = React.lazy(() => import('./main/Account/PersonalDetails/PersonalDetails'));
const ChangePassword = React.lazy(() => import('./main/Account/ChangePassword/ChangePassword'));
const AccountInformation = React.lazy(() => import('./main/Account/AccountInformation/AccountInformation'));
const FavouriteList = React.lazy(() => import('./main/Account/FavouriteList/FavouriteList'));
const PinaPromoPageRecoveryPassword = React.lazy(() => import('./main/Auth/PageRecoveryPassword'));

// Import Promo and Merchant
const PromotionsList = React.lazy(() => import('./main/Promotion/PromotionList/PromotionList'));
const PromotionsListingDetail = React.lazy(() => import('./main/Promotion/PromotionListingDetail/PromotionListingDetail'));
const MerchantList = React.lazy(() => import('./main/Merchant/MerchantList/MerchantList'));
const MerchantListingDetail = React.lazy(() => import('./main/Merchant/MerchantListingDetail/MerchantListingDetail'));

const SpecialsList = React.lazy(() => import('./main/Specials/SpecialsList'));
const SpecialsListingDetail = React.lazy(() => import('./main/Specials/SpecialsListingDetail'));
// Import Terms and Privacy
const Terms = React.lazy(() => import('./main/Terms and Privacy/PageTerms'));
const Privacy = React.lazy(() => import('./main/Terms and Privacy/PagePrivacy'));

// Import Welcome
const PageWelcome = React.lazy(() => import('./main/Auth/PageWelcomeCreateUser/PageWelcomeCreateUser'));
// Import Admin
const AdminDashboard = React.lazy(() => import('./main/Account/AdminDashboard/AdminDashboard'));

const routes = [

    //Special Pages
    { path: '/page-comingsoon', component: PageComingSoon, isWithoutLayout : true },
    { path: '/page-error', component: PageError, isWithoutLayout : true },
    { path: '/page-maintenance', component: PageMaintenance, isWithoutLayout : true },
    
    //Help Center
    { path: '/helpcenter-overview', component: HelpCenterOverview },
    { path: '/helpcenter-faqs', component: HelpCenterFaqs },
    { path: '/helpcenter-guides', component: HelpCenterGuides },
    { path: '/helpcenter-support-request', component: HelpCenterSupportRequest },
    
    //Index Home
    { path: '/index', component: PinaPromoHome },
    { path: '/auth-login', component: PinaPromoLogin, isWithoutLayout : false },
    { path: '/auth-signup', component: PinaPromoSignup, isWithoutLayout : false },
    { path: '/auth-re-password', component: PinaPromoPageRecoveryPassword, isWithoutLayout : false },
    { path: '/personal-details', component: PersonalDetails, isWithoutLayout : false },
    { path: '/change-password', component: ChangePassword, isWithoutLayout : false },
    { path: '/account-information', component: AccountInformation, isWithoutLayout : false },
    { path: '/favourites', component: FavouriteList, isWithoutLayout : false },
    { path: '/promotions', component: PromotionsList, isWithoutLayout : false },
    { path: '/promotion-detail', component: PromotionsListingDetail, isWithoutLayout : false },
    { path: '/specials', component: SpecialsList, isWithoutLayout : false },
    { path: '/special-detail', component: SpecialsListingDetail, isWithoutLayout : false },
    { path: '/merchants', component: MerchantList, isWithoutLayout : false },
    { path: '/merchant-detail', component: MerchantListingDetail, isWithoutLayout : false },
    { path: '/termsandconditions', component: Terms, isWithoutLayout : false },
    { path: '/privacypolicy', component: Privacy, isWithoutLayout : false },

    //Welcome 
    { path: '/welcome', component: PageWelcome, isWithoutLayout : false },

    //Admin
    { path: '/adminDashboard', component: AdminDashboard, isWithoutLayout : false },

    { path: '/', component: PinaPromoHome, isWithoutLayout : true, exact : true }, 
    { component: PageError, isWithoutLayout : true, exact : false },
    
];

export default routes;