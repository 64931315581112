import { gql } from '@apollo/client';

export const GET_USER_BY_AUTH = gql`
query {
    GetUserByAuth {
        _id
        email
        firstName
        lastName
        phone
        website
        firebaseUid
        updatedAt
        createdAt
        role {
          _id
          title
        }
        image {
          _id
          imageFullUrl
          imageThumbUrl
        }
        savedPromos {
          _id
          updatedAt
          title
          category
          shortDesc
          industry
          conditions
          fromDate
          toDate
          price
          priceType
          discount
          fullAddress
          numberOfReviews
          totalReviewRating
          logoImage {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
          }
          merchant {
            _id
            createdAt
            updatedAt
            title
          }
        }
        followedMerchants {
          _id
          updatedAt
          title
          desc
          logoImage {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
          }
          industry
          numberOfReviews
          totalReviewRating
        }
        account {
          _id
          updatedAt
          holder {
              _id
              updatedAt
              email
              firstName
              lastName
          }
          users {
            updatedAt
            _id
          }
          company {
            _id   
            updatedAt
            name
            companyNumber
            phone
            nzbn
            companyStatus
            incorporationDate
            fullAddress
            tradingName
            merchants {
              _id
              title
              staffUsers {
                _id
              }
            }
          }
          subscriptionDetail{
              _id
              updatedAt
              title
              renewedAt
              subscriptionPlan {
                  _id
                  updatedAt
                  price
                  title
                  price
                  renewByMonth
                  currency {
                    _id
                    title
                    symbol
                }
              }
          }
        }
    }
  }
`;

export const UPDATE_USER = gql`
mutation UpdateUser($firstName: String, $lastName: String, $website: String, $phone: String, $imageId: String) {
  UpdateUser(userUpdateInput:{
    firstName: $firstName
    lastName: $lastName
    website: $website
    phone: $phone
    imageId: $imageId
    verified: true
  }) {
    _id
    email
    firstName
    lastName
    phone
    website
    firebaseUid
    updatedAt
    createdAt
    role {
      _id
      title
    }
    image {
      _id
      imageFullUrl
      imageThumbUrl
    }
    account {
      _id
      holder {
          _id
          email
          firstName
          lastName
      }
      users {
          _id
      }
      company {
        _id   
        name
        companyNumber
        phone
        nzbn
        companyStatus
        incorporationDate
        fullAddress
        tradingName
      }
      subscriptionDetail{
          _id
          title
          renewedAt
          subscriptionPlan {
              _id
              price
              title
              price
              renewByMonth
              currency {
                _id
                title
                symbol
            }
          }
      }
    }
  }
}
`;

export const CREATE_USER_BY_AUTH = gql`
mutation CreateUserByAuth($firstName: String!, $lastName: String!, $email: String!) {
  CreateUserByAuth(userCreateInput:{
    firstName: $firstName
    lastName: $lastName
    email: $email
  }) {
    _id
    email
    lastName
    updatedAt
    createdAt
    role {
        _id
        title
    }
  }
}
`;

export const CREATE_USER_BY_FIREBASE = gql`
mutation CreateUserByFireBase($firebaseUid: String!, $firstName: String!, $lastName: String!, $email: String!) {
  CreateUserByFireBase(userFirebaseCreateInput:{
    firebaseUid: $firebaseUid
    firstName: $firstName
    lastName: $lastName
    email: $email
  }) {
    _id
    email
    lastName
    updatedAt
    createdAt
    role {
        _id
        title
    }
  }
}
`;

export const UPDATE_USER_BY_EXIST_ACCOUNT = gql`
mutation UpdateUserByExistAccount($firebaseUid: String!, $email: String!) {
  UpdateUserByExistAccount(userUpdateByExistAccountInput:{
    firebaseUid: $firebaseUid
    email: $email
  }) {
    _id
    email
    updatedAt
    createdAt
    role {
        _id
        title
    }
  }
}
`;

export const ADD_USER_SAVED_PROMO = gql`
mutation AddUserSavedPromo($promoId: String!
                        ) {
            AddUserSavedPromo(userAddSavedPromoInput:{
                promoId: $promoId
            }) {
        _id
        updatedAt
        createdAt
        savedPromos {
          _id
          updatedAt
          title
          category
          shortDesc
          industry
          conditions
          fromDate
          toDate
          price
          priceType
          discount
          fullAddress
          numberOfReviews
          totalReviewRating
          logoImage {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
          }
          merchant {
            _id
            createdAt
            updatedAt
            title
          }
        }
        followedMerchants {
          _id
          updatedAt
          title
          industry
          numberOfReviews
          totalReviewRating
        }
    }
}
`;

export const REMOVE_USER_SAVED_PROMO = gql`
mutation RemoveUserSavedPromo($promoId: String!
                        ) {
            RemoveUserSavedPromo(userRemoveSavedPromoInput:{
                promoId: $promoId
            }) {
        _id
        updatedAt
        createdAt
        savedPromos {
          _id
          updatedAt
          title
          category
          shortDesc
          industry
          conditions
          fromDate
          toDate
          price
          priceType
          discount
          fullAddress
          numberOfReviews
          totalReviewRating
          logoImage {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
          }
          merchant {
            _id
            createdAt
            updatedAt
            title
          }
        }
    }
}
`;

export const ADD_USER_FOLLOWED_MERCHANT = gql`
mutation AddUserSavedPromo($merchantId: String!
                        ) {
                          AddUserFollowedMerchant(userAddFollowedMerchantInput:{
              merchantId: $merchantId
            }) {
        _id
        updatedAt
        createdAt
        savedPromos {
          _id
          updatedAt
          title
          category
          shortDesc
          industry
          conditions
          fromDate
          toDate
          price
          priceType
          discount
          numberOfReviews
          totalReviewRating
          logoImage {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
          }
          merchant {
            _id
            createdAt
            updatedAt
            title
          }
        }
        followedMerchants {
          _id
          updatedAt
          title
          industry
          numberOfReviews
          totalReviewRating
        }
    }
}
`;

export const REMOVE_USER_FOLLOWED_MERCHANT = gql`
mutation RemoveUserSavedPromo($merchantId: String!
                        ) {
                          RemoveUserFollowedMerchant(userRemoveFollowedMerchantInput:{
                            merchantId: $merchantId
                        }) {
        _id
        updatedAt
        createdAt
        followedMerchants {
          _id
          updatedAt
          title
          industry
          numberOfReviews
          totalReviewRating
        }
    }
}
`;