import React, { Component, Suspense } from 'react';
import MainLayout from './components/Layout';
import { Route, Switch, BrowserRouter as Router, withRouter } from 'react-router-dom';

//Impot State Data
import { InitStateData } from './helpers/stateDataHelper'
//Import Firebase
import { firebaseWeb } from './firebase/index'

//Import Session
import { removeFirebaseUser } from './helpers/sessionHelper';

//Import Context
import AuthContext from './context/auth-context';
import { updateAuth } from './context/AuthManager';

import { ApolloProvider } from '@apollo/client';
import graphqlClient from '../src/graphql/index'

//Import Env
import { DEBUG } from './env'

import TagManager from 'react-gtm-module'

// Import Css
import './Apps.scss';
import './assets/css/materialdesignicons.min.css';
import './assets/css/colors/default.css';

// Include Routes 
import routes from './routes';

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return <MainLayout>
        <WrappedComponent></WrappedComponent>
      </MainLayout>
    }
  };
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...InitStateData()
    };
  }

  appLogin = async (email, password) => {
    var errMessage = null;
    const userCredential = await firebaseWeb().auth().signInWithEmailAndPassword(email, password).catch(function (error) {
      var errorCode = error.code;
      if (DEBUG() === true) console.log('errorCode' + errorCode);
      errMessage = error.message;
      if (DEBUG() === true) console.log('errMessage:' + errMessage);
    });

    return {
      userCredential: userCredential,
      errMessage: errMessage,
    };
  }

  appLogout = async () => {
    removeFirebaseUser();
    await firebaseWeb().auth().signOut().then(() => {
      if (DEBUG() === true) console.log("Sign-out successful");
    }).catch((error) => {
      // An error happened.
      if (DEBUG() === true) console.log("Sign-out error" + error);
    });
    this.redirectHome();
  };

  redirectHome = () => {
    window.location.href = "/";
  }

  componentDidMount() {
    if (DEBUG() === true) console.log('App componentDidMount');
    updateAuth(this, firebaseWeb());
  }

  componentWillUnmount() {

  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  }
  render() {

    if (DEBUG() === false) {
      const tagManagerArgs = {
        dataLayer: {
          email: this.state.userData.email,
          id: this.state.userData._id
        }
      }

      TagManager.dataLayer(tagManagerArgs);
    }

    if (DEBUG() === true) console.log('App render user role title:' + this.state.userData.role.title);
    if (DEBUG() === true) console.log('App render user email:' + this.state.userData.email);
    if (DEBUG() === true) console.log('App render user id:' + this.state.userData._id);


    return (
      <ApolloProvider client={graphqlClient}>
        <React.Fragment>
          <AuthContext.Provider
            value={{
              isLogin: this.state.isLogin,
              userData: this.state.userData,
              appLogin: this.appLogin,
              appLogout: this.appLogout,
              redirectHome: this.redirectHome,
              firebase: firebaseWeb()
            }}
          >
            <Router>
              <Suspense fallback={this.Loader()} >
                <Switch>
                  {routes.map((route, idx) =>
                    route.isWithoutLayout ?
                      <Route path={route.path} exact={route.exact} render={route.render} component={route.component} key={idx} />
                      :
                      <Route path={route.path} exact render={route.render} component={withLayout(route.component)} key={idx} />
                  )}
                </Switch>
              </Suspense>
            </Router>
          </AuthContext.Provider>

        </React.Fragment>
      </ApolloProvider>
    );
  }
}

export default withRouter(App);